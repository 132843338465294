
<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
          <statistics-card-line
            icon="UsersIcon"
            :hideChart="true"
            :statistic="result.users.total"
            statisticTitle="Registered Buyers"
            type='area' />
      </div>

      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
          <statistics-card-line
            icon="CoffeeIcon"
            :hideChart="true"
            :statistic="result.products"
            statisticTitle="Total Products"
            color='success'
            type='area' />
      </div>

      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <statistics-card-line
          icon="ShoppingCartIcon"
          :hideChart="true"
          :statistic="result.orders.total"
          statisticTitle="Total Orders"
          color='danger'
          type='area' />
      </div>
      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <statistics-card-line
          icon="AwardIcon"
          :hideChart="true"
          :statistic="result.reviews"
          statisticTitle="Total Reviews"
          color='warning'
          type='area' />
      </div>
    </div>
    <div class="vx-row">
      <!-- LINE CHART -->
      <div class="vx-col w-full lg:w-2/5 mb-base lg:mt-0">
        <vx-card title="Orders">
          <template slot="actions">
            <change-time-duration-dropdown />
          </template>
          <div slot="no-body">
            <order-report :order-data="result.orders"/>
          </div>
        </vx-card>
      </div>
      <div class="vx-col lg:w-3/5 w-full mb-base">
        <vx-card title="Revenue">
          <template slot="actions">
            <feather-icon icon="SettingsIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
          </template>
          <div slot="no-body" class="p-6 pb-0">
            <revenue-report :revenue-data="result.revenueData" />
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
import RevenueReport from '@/components/widgets/RevenueReport.vue'
import OrderReport from '@/components/widgets/OrderReport.vue'

export default{
    components: {
      StatisticsCardLine,
      VuePerfectScrollbar,
      ChangeTimeDurationDropdown,
      RevenueReport,
      OrderReport
    },
    data() {
      return {
        isLoading: true,
        result:{
          'users':{
            "admin": 0,
            "buyer": 0,
            "support": 0,
            "total": 0
          },
          'orders': {
            "total": 0,
            "draft": 0,
            "pending": 0,
            "delivered": 0,
            "completed": 0,
            "refunded": 0
          },
          "revenueData":{
            "current_month":0,
            "last_month": 0,
            "total_revenue": 0,
            "series":[]
          },
          'products': 0,
          'reviews': 0,
        }
      }
    },
    created() {
     this.$http.get(`/analytics`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.result = response.data.data
        }
      })
    }
}
</script>

<style lang="scss">
.chat-card-log {
    height: 400px;

    .chat-sent-msg {
        background-color: #f2f4f7 !important;
    }
}

.vx-card.system-user-chart .vx-card__body{
  padding-bottom:5px;
}
</style>
