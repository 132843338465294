<template>
    <div class="" dir="ltr">
        <div class="flex">
            <div class="mr-6">
                <p class="mb-1 font-semibold">Total Revenue</p>
                <p class="text-3xl"><sup class="text-base mr-1">$</sup>{{ revenueData.total_revenue | money_format }}</p>
            </div>
        </div>
        <vue-apex-charts class="apex-charts" type="area"
        :options="areaChartOptions" height="266" :series="seriesData" />
    </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        VueApexCharts,
    },
    props:{
        revenueData:{
            default:() => ({
                total_revenue: 0,
                series:[]
            })
        }
    },
    data() {
        return {
            areaChartOptions: {
                chart: {
                    id: "vuechart-revenue-report",
                    stacked: true,
                    toolbar: {
                        show: false
                    }
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        opacityFrom: 0.3,
                        opacityTo: 0.9
                    }
                },
                legend: {
                    offsetY: 5
                },
                xaxis: {
                    type: "datetime"
                },
                grid: {
                    padding: {
                        bottom: 10
                    }
                },
                yaxis: {
                    tickAmount: 5,
                    labels: {
                        style: {
                            cssClass: 'text-grey fill-current',
                        },
                        formatter: function(val) {
                            return val > 999 ? (val / 1000).toFixed(1) + 'k' : val;
                        }
                    }
                },
                colors: ["#1abc9c"],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [2],
                    curve: "smooth"
                }
            },
            series: [
                {
                    name: "Total Amount",
                    data: this.revenueData.series.map((i) =>{
                        return [i.month, i.total_amount]
                    })
                },
            ],
            revenueComparisonLine: {
                series: [
                    {
                    name: "Total Amount",
                    data: [45000, 47000, 44800, 47500, 45500, 48000, 46500, 48600]
                    },
                ],
            },
            lineChartOptions: {
                chart: {
                    toolbar: { show: false },
                    dropShadow: {
                        enabled: true,
                        top: 5,
                        left: 0,
                        blur: 4,
                        opacity: 0.10,
                    },
                },
                stroke: {
                    curve: 'smooth',
                    dashArray: [0, 8],
                    width: [4, 2],
                },
                grid: {
                    borderColor: '#e7e7e7',
                },
                legend: {
                    show: false,
                },
                colors: ['#1e1e1e', '#01f4a7'],
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        inverseColors: false,
                        gradientToColors: ['#01f4a7', '#7367F0'],
                        shadeIntensity: 1,
                        type: 'horizontal',
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100, 100, 100]
                    },
                },
                markers: {
                    size: 0,
                    hover: {
                        size: 5
                    }
                },
                xaxis: {
                    type: "datetime",
                    labels: {
                        style: {
                            cssClass: 'text-grey fill-current',
                        }
                    },
                    axisTicks: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                },
                yaxis: {
                    tickAmount: 5,
                    labels: {
                        style: {
                            cssClass: 'text-grey fill-current',
                        },
                        formatter: function(val) {
                            return val > 999 ? (val / 1000).toFixed(1) + 'k' : val;
                        }
                    }
                },
                tooltip: {
                    x: { show: false }
                }
            }
        };
    },
    computed:{
        seriesData(){
            return [
                {
                    name: "Total Amount",
                    data: this.revenueData.series.map((i) =>{
                        return [i.month, i.total_amount]
                    })
                },
            ]
        }
    }
};
</script>


