<template>
    <div class="">
        <vue-apex-charts type=pie height=150 class="mt-3 mb-4" 
            :options="chartOptions" :series="series" />
        <ul class="mb-1">
            <li v-for="item in analyticsData" :key="item.status" class="flex justify-between py-3 px-6 border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-b-0">
                <span class="flex items-center">
                    <span class="inline-block h-3 w-3 rounded-full mr-2" :class="`bg-${item.color}`"></span>
                    <span class="font-semibold">{{ item.status }}</span>
                </span>
                <span>{{ item.counts }}</span>
            </li>
        </ul>
    </div>
</template>
<script>

import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        VueApexCharts,
    },
    props:{
        orderData:{
            default:() => ({})
        }
    },
    data() {
        return {
            chartOptions: {
                labels: ['Pending', 'Delivered', 'Completed', 'Refunded'],
                dataLabels: {
                    enabled: false
                },
                legend: { show: false },
                chart: {
                    type: 'pie',
                    offsetY: 0,
                    dropShadow: {
                        enabled: false,
                        blur: 5,
                        left: 1,
                        top: 1,
                        opacity: 0.2
                    },
                    toolbar: {
                        show: false
                    }
                },
                stroke: {
                    width: 0
                },
                colors: ['#1e1e1e', '#ff9f43', '#28c76f', '#ed6a6b'],
                fill: {
                    type: 'gradient',
                    gradient: {
                        gradientToColors: ['#534f4f', '#FFC085', '#42d985', '#f4a8a9']
                    }
                }
            },
            
            // series: [{
            //     name: "Total Earnings",
            //     data: this.seriesData.map((i) =>{
            //         return [i.month, i.total_amount]
            //     })
            // },],
        };
    },
    computed:{
        analyticsData(){
            return [
                { status: 'Draft', counts: this.orderData.draft, color: 'danger' },
                { status: 'Pending', counts: this.orderData.pending, color: 'primary' },
                { status: 'Delivered', counts: this.orderData.delivered, color: 'warning' },
                { status: 'Completed ', counts: this.orderData.completed, color: 'success' },
                { status: 'Refunded ', counts: this.orderData.refunded, color: 'danger' },
            ]
        },
        series(){
            return [this.orderData.draft, this.orderData.pending, this.orderData.delivered, this.orderData.completed, this.orderData.refunded]
        }
    }
};
</script>


